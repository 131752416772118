import { EpUpload } from '@/utils';
import compressImgOrVideo from '@/utils/compress.tsx';
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, InputNumber, Modal, Select, Upload, message } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import type { FinanceDetailStore } from './model';
import { debounce } from 'underscore';

export const AddModal = observer((props: { store: FinanceDetailStore }) => {
  const {
    addModal,
    onChangeModal,
    detailTypeOption,
    onAddItem,
    chooseDetailType,
    changeDetailType,
    employerList,
    serviceList,
    getEmployerList,
    loadMoreEmployerList,
  } = props.store;

  return (
    <Modal
      title="新增明细"
      open={addModal}
      onCancel={() => onChangeModal('addModal', false)}
      width={600}
      footer={null}
    >
      <Form
        className="pt-4"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        onFinish={onAddItem}
      >
        <Form.Item
          rules={[{ required: true, message: '请选择' }]}
          name="source"
          label="收支名称"
        >
          <Select
            onChange={(e) => changeDetailType(e)}
            options={detailTypeOption.filter((item) => item.remark === '1')}
          />
        </Form.Item>

        {chooseDetailType === 4 ? (
          <>
            <Form.Item
              rules={[{ required: true, message: '请选择' }]}
              name="userId"
              label="选择雇主"
            >
              <Select
                optionFilterProp="label"
                showSearch={true}
                options={employerList}
                labelInValue={true}
                onPopupScroll={(e: any) => {
                  const { scrollTop, scrollHeight, offsetHeight } = e.target;
                  if (scrollTop + offsetHeight >= scrollHeight) {
                    loadMoreEmployerList();
                  }
                }}
                onSearch={debounce((name) => {
                  getEmployerList({ name });
                }, 500)}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: '请选择' }]}
              name="packageId"
              label="选择套餐"
            >
              <Select options={serviceList} />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: '请输入' }]}
              name="buyCount"
              label="次数"
            >
              <InputNumber
                className="w-full"
                max="100000000"
                min="1"
                step={1}
                formatter={(value) => {
                  if (!value) {
                    return '0';
                  }
                  return Number.parseInt(value).toString() || '0';
                }}
                controls={true}
              />
            </Form.Item>
          </>
        ) : (
          ''
        )}

        <Form.Item
          name="amount"
          rules={[{ required: true, message: '请输入' }]}
          label="金额"
        >
          <InputNumber
            className="w-full"
            max="100000000"
            suffix="元"
            formatter={(value) => {
              if (value) {
                return `${Number.parseFloat(Number(value).toFixed(2))}`;
              }
              return value;
            }}
            controls={false}
          />
        </Form.Item>
        <Form.Item
          name="detailContent"
          label="明细内容"
        >
          <Input.TextArea
            maxLength={200}
            showCount={true}
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
        <ImageUploadMultiple
          name="imageList"
          label="上传凭证"
          message=""
        />
        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
          <Button
            type="default"
            className="mr-2"
            onClick={() => onChangeModal('addModal', false)}
          >
            取消
          </Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});

const ImageUploadMultiple = observer(
  (props: { readonly name: string; readonly label: string; readonly message: string }) => {
    const [loading, setLoading] = useState(false);
    return (
      <Form.Item
        noStyle={true}
        shouldUpdate={true}
      >
        {({ getFieldValue, setFieldValue }) => {
          const imageUrl = getFieldValue(props.name) || [];
          const customRequest = async (options: any) => {
            const type = options.file.type;
            if (type.indexOf('image') === -1) {
              message.info('请上传图片～');
              return;
            }

            const file: any = await compressImgOrVideo(options.file);
            if (file === false) {
              return;
            }
            setLoading(true);
            const url = await EpUpload.multiUpload({
              attachmentType: 1,
              module: 'companyAlbum',
              fileList: [file],
            });

            const nowImageUrl = getFieldValue(props.name) || [];
            const arr = [...nowImageUrl, ...url];
            setFieldValue(props.name, arr);
            setLoading(false);
          };

          const beforeUpload = (file) => {
            // 限制文件大小,例如限制为 2MB
            const isLt6M = file.size / 1024 / 1024 < 6;
            if (!isLt6M) {
              console.error('图片需要小于6MB!');
              return false;
            }
            return true;
          };

          const onDelete = (item) => {
            const nowImageUrl = getFieldValue(props.name) || [];
            nowImageUrl.splice(nowImageUrl.indexOf(item), 1);
            setFieldValue(props.name, nowImageUrl);
          };

          return (
            <Form.Item
              label={props.label}
              name={props.name}
              getValueFromEvent={(e) => imageUrl}
              validateTrigger={false}
            >
              <div className="flex items-center gap-2 flex-wrap">
                {imageUrl?.map((item) => {
                  return (
                    <div
                      key={`${item.toString()}`}
                      className="flex items-center justify-center w-[100px] h-[100px] rounded-2 box-content"
                      style={{
                        border: '1px solid #d9d9d9',
                      }}
                    >
                      <Image
                        style={{
                          width: '80px',
                          height: '80px',
                          objectFit: 'cover',
                        }}
                        src={item}
                        preview={{
                          mask: (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onDelete(item);
                              }}
                            >
                              <DeleteOutlined style={{ fontSize: 24 }} />
                            </div>
                          ),
                        }}
                      />
                    </div>
                  );
                })}
                <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={customRequest}
                  beforeUpload={beforeUpload}
                  multiple={true}
                >
                  {imageUrl?.length < 3 ? (
                    <button
                      style={{
                        border: 0,
                        background: 'none',
                      }}
                      className="w-full h-full flex flex-col items-center justify-center"
                      type="button"
                    >
                      {loading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div className="mt-2">上传</div>
                    </button>
                  ) : (
                    ''
                  )}
                </Upload>
              </div>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  },
);
